import React, {useState, useEffect} from "react"

import Layout from "../components/layout"

// Temporary Data
import commonData from "../data/common-data.json"
import agentBuilding from "../data/agent-building.json"
import propertyDetails from "../data/property-details.json"

// Components
import {CustomLinks} from "../components/common/menuUtils"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import PropertyOverview from "../layout/AgentBuilding/PropertyOverview"
import Description from "../layout/AgentBuilding/Description"
import AdditionalInformation from "layout/AgentBuilding/AdditionalInformation"
import AvailableApartments from "layout/AgentBuilding/AvailableApartments"
import AboutUs from "layout/AgentBuilding/AboutUs"

import LocRatingMap from "../components/LocratingMap/locrating-map-details"
import SimilarProperties from "../layout/PropertyDetails/SimilarProperties"
import AreaGuide from "../layout/PropertyDetails/AreaGuide"

import {GetAPIData, Building_API} from "../components/common/agentAPI"
import LoaderLogo from "../components/LoaderLogo"
import {isLoggedIn, getUser} from "../services/auth"

const PropertyDetails = () => {
    const [ details, setGetDetails ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [isAuth, setIsAuth] = useState(isLoggedIn());

    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let property_slug = pageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    useEffect(()=>{
        var api_link = Building_API+id+`?request_from=website`
        GetAPIData(api_link).then(async apiRes => {
            if(apiRes?.success === true){
                setGetDetails(apiRes)
                setLoading(false)
            }
        });
    },[id])

    // Loading logo 
    if (loading) return (
        <LoaderLogo />
    );
    // Loading logo

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = 51.5787466     
    mapItem['lng']  = 51.5787466
    mapItem['id']  = details?.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    // Get Map Details

    return (
        <>
        {details && 
           <Layout template="property_details" Meta_Title={details.display_address} Meta_Description={details.display_address}>
                <BreadCrumbs
                    breadCrumbsData={ 
                        [{
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: "Our Building",
                            link: "/"+CustomLinks.our_building+"/"
                        },
                        {
                            name: details?.display_address ? details?.display_address : details?.building
                            // link: '#'
                        }]
                    }
                />
                <PropertyOverview details={details} isAuth={isAuth}/>
                <Description details={details} isAuth={isAuth}/>
                {isAuth && 
                    <AdditionalInformation details={details} isAuth={isAuth}/>
                }
                <AvailableApartments details={details} isAuth={isAuth}
                    availableApartmentsData={ agentBuilding.AvailableProperties }
                />
                <AboutUs details={details} />

                {!isAuth && 
                <>
                    <div className="property-details-landing-map" id="property-details-map" data-item="property-details-map">
                        <LocRatingMap data={mapItems} />
                    </div>
                    {details?.town && <AreaGuide area={details?.town}/>}
                    <SimilarProperties similarPropertiesData={ propertyDetails.SimilarProperties } />
                </>
                }
            </Layout>
        }
        </>
    )
}

export default PropertyDetails